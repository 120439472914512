<template>
  <div class="center">
    <div class="search">
      <div class="ing pointer" @click="switchto">
        <div :style="{ color: isaction ? '#44A7FC' : '#000' }">进行中</div>
        <div :class="isaction ? 'action' : 'notaction'"></div>
      </div>
      <div class="ed pointer" @click="switchto">
        <div :style="{ color: isaction ? '#000' : '#44A7FC' }">其他</div>
        <div :class="isaction ? 'notaction' : 'action'"></div>
      </div>
    </div>
    <div class="list">
      <div
        :key="item.serviceOrderTransportRelationId"
        class="table"
        v-for="item in tableDate"
      >
        <div class="top">
          <div style="color: #333333">
            订单号：{{ item.orderNo }} &nbsp;&nbsp;|&nbsp;&nbsp; 下单人：{{
              item.consumerName
            }}
            &nbsp;&nbsp; {{ item.consumerTelephone }}
          </div>
          <div>
            <CountDown
              v-if="item.transportOrderStatus == 0"
              style="color: red; margin-right: 10px"
              @getList="getList"
              :endDate="`${item.timestamp}`"
            ></CountDown>
            <i class="hy-icon-shijian1"></i> {{ item.createTime }}
            <span class="status">{{
              transportOrderStatus[item.transportOrderStatus]
            }}</span>
          </div>
        </div>
        <div class="bottom">
          <div class="left pointer" @click="todetail(item.serviceOrderId)">
            <div class="img">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.mainGraph"
              />
            </div>
            <div class="text">
              <div class="first f-hide" style="width: 400px">
                {{ item.name }}
              </div>
              <div class="two">货物名称：{{ item.goodName }}</div>
              <div class="two">价格/吨：{{ item.price }}元/吨</div>
              <div class="two">发货量：{{ item.num }}吨</div>
            </div>
          </div>
          <div class="middle">
            <span class="one">订单金额： </span><span class="two">¥</span
            ><span class="three">{{ item.allprice }}</span>
          </div>
          <div class="right">
            <el-button
              v-if="
                item.transportOrderStatus == 1 || item.transportOrderStatus == 2
              "
              style="width: 104px"
              type="primary"
              @click="toaplay(item)"
              plain
              size="mini"
              >去支付</el-button
            >
            <el-button
              v-if="item.transportOrderStatus == 0"
              style="width: 104px"
              type="primary"
              @click="deailorder(item.serviceOrderId)"
              plain
              size="mini"
              >取消订单</el-button
            >
            <el-button
              v-if="
                item.transportOrderStatus == 1 || item.transportOrderStatus == 2
              "
              type="primary"
              plain
              size="mini"
              @click="uploadCar(item.serviceOrderId)"
              >查看车辆信息</el-button
            >
            <el-button
              v-if="
                item.transportOrderStatus == 2 || item.transportOrderStatus == 6
              "
              type="primary"
              @click="querycar(4, item.serviceOrderId, false)"
              plain
              size="mini"
              >确认服务完成</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="allpage">
      <el-pagination
        background
        :total="total"
        :page-size="formInline.pageSize"
        :current-page="formInline.pageNum"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-dialog
      class="zyr_dialog"
      title="支付运费"
      :visible.sync="dialogVisiblezf"
      width="661px"
      :before-close="zfhandleClose"
    >
      <div class="dialog_center">
        <el-form
          :model="aplayform"
          :rules="rules"
          ref="dynamicValidateForm"
          label-width="150px"
          class="demo-dynamic"
        >
          <el-form-item label="运输数量：" prop="trafficCount">
            <el-input
              size="small"
              @input="
                aplayform.trafficCount = aplayform.trafficCount.replace(
                  /[^\d^\.]/g,
                  ''
                )
              "
              @blur="changeall"
              maxlength="5"
              placeholder="请输入运输数量"
              v-model="aplayform.trafficCount"
            ></el-input>
          </el-form-item>
          <el-form-item label="运输单价(元/吨)：" prop="trafficPrice">
            <el-input
              size="small"
              v-model="aplayform.trafficPrice"
              @input="
                aplayform.trafficPrice = aplayform.trafficPrice.replace(
                  /[^\d^\.]/g,
                  ''
                )
              "
              maxlength="5"
              placeholder="请输入单价"
              @blur="changeall"
            ></el-input>
          </el-form-item>
          <el-form-item label="需要支付金额：" prop="num">
            {{ "￥" + allprice }}
          </el-form-item>
          <el-form-item label="支付方式：" prop="num">
            <span>账户余额（余额：￥{{ yue }}）</span>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="zfhandleClose">取 消</el-button>
        <el-button type="primary" @click="handleSure">立即支付</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="zyz_dialog"
      title="查看车辆信息"
      :visible.sync="dialogVisible"
      width="663px"
      :before-close="handleClose"
    >
      <div :class="number ? 'dialog_center' : 'dialog_centers'">
        <div :key="i" v-for="(item, i) in carList" class="dialog_item">
          {{ item ? item.plateNo : "" }}
        </div>
      </div>
      <div class="allpage page">
        <el-pagination
          background
          :total="totals"
          :page-size="form.pageSize"
          :current-page="form.pageNum"
          @current-change="handleCurrentChanges"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <!-- <keyworder
      :isShow.sync="passWordIsShow"
      :row="{ orderPrice: allprice }"
      ref="InputPasswordDialog"
      title="请输入交易密码"
      @submit="submit"
    /> -->
    <passWord
      :isShow="passWordIsShow"
      title="输入支付密码"
      @close="passWordIsShow = false"
      @submit="submit"
    />
  </div>
</template>

<script>
import {
  listServiceOrder,
  dealServiceOrder,
  applymenoy,
  queryyue,
  listTransportCars,
} from "@/api/home";
import CountDown from "@/components/datedown.vue";
import passWord from "@/components/InputPasswordDialog2.vue";
const { baseUrl } = require("@/config/index.js");
import * as types from "@/store/action-types";
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";
import Vue from "vue";
import md5 from "js-md5";
let uploadfoo = null;
export default {
  data() {
    return {
      yue: "0",
      allprice: "0",
      passWordIsShow: false,
      carList: [],
      aplayform: {
        trafficPrice: "",
        trafficCount: "",
      },
      rules: {
        trafficPrice: [
          { required: true, message: "请输入运输单价", trigger: "blur" },
        ],
        trafficCount: [
          { required: true, message: "请输入运输数量", trigger: "blur" },
        ],
      },
      transportOrderStatus: [
        "未接单",
        "待付款",
        "已付款",
        "已完成",
        "已拒绝",
        "已取消",
        "对方已确认",
        "等待对方确认",
      ],
      tableDate: [],
      dialogVisible: false,
      dialogVisiblezf: false,
      isaction: true,
      total: 0,
      totals: 0,
      size: 4,
      form: {
        serverOrderId: "",
        pageSize: 10,
        pageNum: 1,
      },
      formInline: {
        consumerId: "",
        dictId: "506",
        pageSize: 3,
        orderStatus: 1,
        pageNum: 1,
      },
      number: "",
    };
  },
  mounted() {
	this.formInline.consumerId = Vue.ls.get(types.enterpriseId);
    this.getList();
  },
  components: {
    CountDown,
    passWord,
  },
  methods: {
    changeall() {
      if (this.aplayform.trafficCount && this.aplayform.trafficPrice) {
        this.allprice =
          (Number(this.aplayform.trafficCount) *
            1000 *
            (Number(this.aplayform.trafficPrice) * 1000)) /
          1000000;
      }
    },
    getoList() {
      listTransportCars(this.form).then((res) => {
        if (res.code == 0) {
          this.carList = res.data.list;
          // this.carList.length = 10;
          // if (this.carList.length%2==1){
          //   this.number = true
          // } else {
          //   this.number = false

          // }
          this.totals = res.data.total;
          if (this.totals > 0) {
            this.dialogVisible = true;
          } else {
            this.$message("服务商还未上传车辆");
          }
        }
      });
    },
    zfhandleClose() {
      this.$refs.dynamicValidateForm.resetFields();
      this.dialogVisiblezf = false;
    },
    submit(payPassword) {
      applymenoy({
        serviceOrderId: uploadfoo,
        payAmount: this.allprice,
        payPassword: md5(payPassword),
      }).then((res) => {
        if (res.code == 0) {
          this.dialogVisiblezf = false;
          this.getList();
          this.$message.success("支付成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    JsESACheckIsKeyk(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        this.passWordIsShow = true;
      } else {
        alert(msg);
        return false;
      }
    },
    handleSure() {
      this.$refs.dynamicValidateForm.validate((valid) => {
        if (valid) {
          ESASignClient.ESACheckIsKey(this.JsESACheckIsKeyk, null, false);
        }
      });
    },
    toaplay(ite) {
      if (!this.beforhandle("personalCenter:plogisticsOrder:ongoing:topay"))
        return;
      uploadfoo = ite.serviceOrderId;
      this.queryYe();
      this.dialogVisiblezf = true;
    },
    queryYe() {
      queryyue().then((res) => {
        if (res.code == 0) {
          this.yue = res.data;
        }
      });
    },
    deailorder(serviceOrderId) {
      if (!this.beforhandle("personalCenter:plogisticsOrder:ongoing:cancel"))
        return;
      this.$confirm("确认要取消订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "hyxy-confirm",
      })
        .then(() => {
          dealServiceOrder({ operateType: 5, serviceOrderId }).then((res) => {
            if (res.code == 0) {
              this.$message.success("订单取消成功");
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    async querycar(operateType, serverOrderId, other) {
      if (!this.beforhandle("personalCenter:plogisticsOrder:ongoing:canfirm"))
        return;
      let foo = { serviceOrderId: serverOrderId, operateType };
      other == 201
        ? (foo.confirmCar = true)
        : other == 202
        ? (foo.confirmPay = true)
        : "";
      dealServiceOrder(foo).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getList();
        } else if (res.code == 201 || res.code == 202) {
          this.$confirm(res.message, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            customClass: "zzyss_confirm",
          })
            .then((e) => {
              this.querycar(operateType, serverOrderId, res.code);
            })
            .catch((e) => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        }
      });
    },
    changestatus(operateType, serviceOrderId) {
      const status = [
        "",
        "确认接单",
        "确认拒绝接单",
        "确认服务完成",
        "确认服务完成",
      ];
      this.$confirm(status[operateType], "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "hyxy-confirm",
      })
        .then(() => {
          dealServiceOrder({ serviceOrderId, operateType }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    uploadCar(id) {
      if (!this.beforhandle("personalCenter:plogisticsOrder:ongoing:car"))
        return;
      this.form.serverOrderId = id;
      this.getoList();
    },
    async getList() {
      const res = await listServiceOrder(this.formInline);
      if (res.code == 0) {
        this.tableDate = res.data.list.map((item) => {
          item.serviceOrderVO.createTime =
            item.serviceOrderVO.createTime.replace("T", " ");
          return {
            ...item.serviceOrderVO,
            mainGraph:
              baseUrl +
              "third/third/ossGetFile/" +
              item.serviceOrderVO.mainGraph,
            ...item.transportInfo,
            allprice:
              (item.transportInfo.num *
                100 *
                (item.transportInfo.price * 100)) /
              10000,
            timestamp: this.datacomponent(item.serviceOrderVO.createTime),
          };
        });
        // console.log(this.tableDate);
        this.total = res.data.total;
      }
    },
    datacomponent(date) {
	  //ie只支持"2019/04/26 12:23:22"格式字符串转时间不支持"2022-08-26 00:00:00"
      let createTime = new Date(Date.parse(date.replace(/-/g,"/"))).getTime() + 10800000;
      let timestamp = new Date().getTime();
      return createTime - timestamp;
    },
    handleCurrentChange(value) {
      this.formInline.pageNum = value;
      console.log(this.formInline);
      this.getList();
    },
    handleCurrentChanges(value) {
      this.form.pageNum = value;
      this.getoList();
    },
    todetail(id) {
      if (
        !this.beforhandle([
          "personalCenter:plogisticsOrder:ongoing:detail",
          "personalCenter:personalCenplogisticsOrder:otherter:detail",
        ])
      )
        return;
      this.$router.push({
        path: "/uslogisticsdetail",
        query: {
          id,
        },
      });
    },
    switchto() {
      this.isaction = !this.isaction;
      if (this.isaction) {
        this.formInline.orderStatus = 1;
      } else {
        this.formInline.orderStatus = 2;
      }
      this.formInline.pageNum = 1;
      this.getList();
    },
    addfrom() {
      this.form.domains.push({
        carnum: "",
        key: Date.now(),
      });
    },
    delfrom(delfrom) {
      let index = this.form.domains.indexOf(delfrom);
      if (index !== -1) {
        this.form.domains.splice(index, 1);
      }
    },
    handleClose() {
      this.form.pageNum = 1;
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss">
.hyxy-confirm {
  .el-message-box {
    width: 450px !important;
    height: 218px !important;
    background: #ffffff !important;
    border-radius: 10px !important;
  }
  .el-message-box__header {
    background: #f8f8f8;
  }
  .el-message-box__title {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
  .el-message-box__content {
    padding: 49px 119px;
    color: #606266;
    font-size: 14px;
  }
  .el-message-box__btns {
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}
</style>
<style lang="scss">
.zyr_dialog {
  .el-dialog {
    border-radius: 10px;
  }
  .el-form-item {
    margin-bottom: 18px;
  }
  .el-dialog__body {
    padding: 80px 150px 43px 150px;
  }
  .el-dialog__header {
    height: 53px;
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px;
    .el-dialog__title {
      font-size: 16px;
      line-height: 16px;
      color: #666666;
    }
  }
  .el-dialog__footer {
    padding: 18px 25px 25px;
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}
.zyz_dialog {
  .el-dialog {
    border-radius: 10px;
  }
  .page {
    padding: 20px 0;
  }
  .el-dialog__header {
    height: 53px;
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px;
    .el-dialog__title {
      font-size: 16px;
      line-height: 16px;
      color: #666666;
    }
  }
  .el-dialog__body {
    padding: 25px 30px 0;
  }
  .el-dialog__footer {
    padding: 18px 25px 25px;
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
  // input[type=text] {
  //   height: 34px;
  // }
  .demo-dynamic {
    width: 401px;
  }
  .el-input {
    width: 211px;
  }
  .dialog_centers {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #e5e5e5;
    border-bottom: 0px solid #000;
  }
  .dialog_centers .dialog_item:nth-child(2n) {
    border-right: none;
  }
  .dialog_centers .dialog_item:last-child(2) {
    border-bottom: none;
  }
  .dialog_center {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #e5e5e5;
  }
  .dialog_center .dialog_item:nth-child(2n) {
    border-right: none;
  }
  .dialog_center .dialog_item:last-child {
    border-bottom: none;
  }
}
</style>
<style lang="scss" scoped>
.dialog_item {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  text-align: center;
  line-height: 60px;
  width: 300px;
  height: 60px;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
.center {
  width: 1195px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .search {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 800;
    .action {
      width: 33px;
      height: 3px;
      margin-top: 3px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
      border-radius: 2px;
    }
    .notaction {
      width: 33px;
      height: 3px;
      margin-top: 3px;
      border-radius: 2px;
    }
    .ing {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      height: 100%;
    }
    .ed {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      height: 100%;
      border-left: 1px solid #e5e5e5;
    }
  }
  .list {
    padding: 16px;
    padding-bottom: 0px;
    .table {
      height: 162px;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      margin-bottom: 16px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 43px;
        padding: 0 16px;
        border-bottom: 1px dashed #e5e5e5;
        color: #999999;
        .status {
          padding-left: 41px;
          color: #0a9d41;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 118px;
        padding: 0 8px;
      }
      .left {
        display: flex;
        .img {
          width: 167px;
          height: 97px;
          border-radius: 2px;
          overflow: hidden;
        }
        .text {
          margin-left: 17px;
          .first {
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #384048;
            margin-top: 4px;
            margin-bottom: 20px;
          }
          .two {
            height: 14px;
            line-height: 14px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            margin-bottom: 4px;
          }
        }
      }
      .middle {
        .one {
          font-size: 12px;
          color: #6d6b6b;
        }
        .two {
          font-size: 16px;
          color: #d0111a;
        }
        .three {
          padding-left: 4px;
          font-size: 28px;
          font-weight: 600;
          color: #d0111a;
        }
      }
      .right {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-end;
        height: 94px;
        padding-right: 17px;
        .status {
          padding: 4px 13px;
          height: 19px;
          line-height: 19px;
          font-size: 12px;
          color: #fff;
          background: #f87b28;
          border-radius: 10px;
        }
        .do {
          width: 127px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #b3d8ff;
          border-radius: 4px;
        }
      }
    }
  }
  .allpage {
    text-align: right;
    margin-bottom: 20px;
    margin-right: 6px;
  }
}
</style>>